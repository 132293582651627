import React, { ChangeEvent, createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FileUploader } from 'react-drag-drop-files';

import JSZip from 'jszip';
import readXlsxFile from 'read-excel-file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Method, Service } from 'shared-components/configuration';
import { BATCH_QUERY } from 'shared-components/queries/graphql';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { UPDATE_WITHDRAWAL_STATUS } from 'shared-components/queries/mutations';
import { generateLbFileContent } from 'shared-components/utils';
import InfoModal from 'components/Modals/Info';

import style from './style.module.scss';

import 'react-calendar-datetime-picker/dist/style.css';

const Batches = () => {
  const date = new Date();
  const yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const timeoutHandler = useRef<any>(null);
  const searchRef = createRef<HTMLInputElement>();

  const [batches, setBatches] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [file, setFile] = useState<File>();
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<any>([]);
  const [searchBox, setSearchBox] = useState<string>('');

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);
      timeoutHandler.current = setTimeout(() => {
        setSearchBox(searchValue);
      }, 1000);
    },
    [setSearchBox]
  );

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(batches.totalCount / perPage);
    if (page === 1) {
      batchesQuery.execute({
        variables: { searchFilter: searchBox.length > 0 ? searchBox : undefined, first: perPage }
      });
    } else if (page >= totalPages) {
      batchesQuery.execute({
        variables: { searchFilter: searchBox.length > 0 ? searchBox : undefined, last: perPage }
      });
    } else if (page > currentPage) {
      batchesQuery.execute({
        variables: {
          searchFilter: searchBox.length > 0 ? searchBox : undefined,
          first: perPage,
          after: batches.pageInfo.endCursor
        }
      });
    } else if (page < currentPage) {
      batchesQuery.execute({
        variables: {
          searchFilter: searchBox.length > 0 ? searchBox : undefined,
          last: perPage,
          before: batches.pageInfo.startCursor
        }
      });
    } else {
      batchesQuery.execute({
        variables: {
          searchFilter: searchBox.length > 0 ? searchBox : undefined,
          first: perPage,
          after: batches.pageInfo.endCursor
        }
      });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
    setTotalRows(batches.totalCount);
    setLoading(false);
  }, [batches]);

  const batchesQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'batches',
    data: {
      query: BATCH_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const batchesData = data?.edges?.map((value: any) => {
        const values = value.node;
        let amount = 0;
        let paid = 0;
        values.payouts = values.payouts?.edges?.map((pvalue: any) => {
          amount = amount + pvalue.node.amount;
          if (pvalue.node.status == 'paid') {
            paid = paid + pvalue.node.amount;
          }
          return pvalue.node;
        });

        values.amount = amount;
        values.paid = paid;
        return values;
      });
      setBatches(batchesData);
    }
  });

  const updateWithdrawalStatusQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateWithdrawalStatus',
    data: {
      query: UPDATE_WITHDRAWAL_STATUS
    },
    auto: false
  });

  const handleFileUpload = useCallback((file: File) => {
    setFile(file);
  }, []);

  const handlePaidWithdrawals = useCallback(() => {
    file
      ?.arrayBuffer()
      .then((data) => readXlsxFile(data))
      .then((rows) => {
        for (const row of rows) {
          updateWithdrawalStatusQuery.execute({
            variables: {
              input: {
                txRef: row.at(1),
                newStatus: 'paid'
              }
            }
          });
        }
      });
  }, [file, updateWithdrawalStatusQuery]);

  const handleDetails = useCallback((row: any) => {
    setDetailsVisible(true);
    setDetailsData(row.payouts);
    console.log(row);
  }, []);

  const handleLBFile = useCallback((row: any) => {
    const date = new Date();
    //const year = date.getFullYear();
    const tdyear = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    //const _formattedDate = `${year}${month}${day}`;
    const tdformattedDate = `${tdyear}${month}${day}`;

    const merchantIban = row.payouts[0].merchant.iban;

    const lbFileContent = generateLbFileContent(
      merchantIban, // Merchant's IBAN for header
      tdformattedDate, // Example creation date
      'LEVERANTÖRSBETALNINGAR', // Product name
      'GENAST', // Payment date
      'SEK', // Currency code
      row.payouts.map((data: any) => ({
        iban: data.clientIban, // Use client IBAN if available, otherwise use merchant IBAN
        internalReference: data.paymentReference, // Use payment reference as the internal reference
        ocrReferens: data.paymentReference, // Using payment reference as OCR reference
        amount: data.amount,
        paymentDate: 'GENAST', // Immediate payment
        infoToSender: data.paymentReference // Optional additional information (using payment reference here)
      }))
    );
    const zip = new JSZip();
    let counter = 1;
    for (const value of lbFileContent) {
      zip.file(`${row.batchId}-${counter}.lb`, value);
      counter = counter + 1;
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const blob = new Blob([content], { type: 'application/zip' });
      const link = document.createElement('a');
      link.download = `${row.batchId}-${row.createdAt}.zip`;
      link.href = URL.createObjectURL(blob);
      document.body.append(link);
      link.click();
      link.remove();
    });
  }, []);

  useEffect(() => {
    handlePageChange(currentPage);
  }, [searchBox]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <InfoModal
        size="xl"
        title={'Batch details'}
        visible={detailsVisible}
        handleClose={() => {
          setDetailsVisible(false);
        }}
        onOk={() => {
          setDetailsVisible(false);
        }}
      >
        <DataTable
          columns={[
            {
              name: 'Created at',
              selector: (row: any) => `${row?.['createdAt'].replace('T', ' ')}`
            },
            {
              name: 'Payout reference',
              selector: (row: any) => row?.['paymentReference']
            },
            {
              name: 'IBAN',
              selector: (row: any) => row?.['clientIban']
            },
            {
              name: 'Amount',
              selector: (row: any) => `${row?.['amount'].toFixed(2)} ${row?.['currency'].toUpperCase()}`
            },
            {
              name: 'Status',
              selector: (row: any) => row?.['status']
            }
          ]}
          data={detailsData}
          pagination
        />
      </InfoModal>
      <Card className={style.card}>
        <Card.Title>
          <div style={{ display: 'flex' }}>
            <div style={{ alignSelf: 'center' }}>Batches &nbsp;</div>
            <div style={{ margin: '10px' }}>
              <FileUploader onSelect={handleFileUpload} onDrop={handleFileUpload} />
            </div>
            <div style={{ alignSelf: 'center' }}>
              <Button onClick={handlePaidWithdrawals}>Mark file entries as paid</Button>
            </div>
          </div>
        </Card.Title>
        <Card.Text>
          <Form>
            <InputGroup size="sm" className="p-0">
              <InputGroup.Text id="search">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                ref={searchRef}
                onChange={onChangeHandler}
              />
            </InputGroup>
          </Form>

          <DataTable
            columns={[
              {
                name: 'Created at',
                selector: (row: any) => row?.['createdAt']
              },
              {
                name: 'Batch ID',
                selector: (row: any) => row?.['batchId']
              },
              {
                name: 'Payouts count',
                selector: (row: any) => `${row?.['payouts'].length}`
              },
              {
                name: 'Total amount',
                selector: (row: any) => `${row?.['amount'].toFixed(2)} SEK`
              },
              {
                name: 'Total paid',
                selector: (row: any) => `${row?.['paid'].toFixed(2)} SEK`
              },
              {
                name: 'Details',
                cell: (row: any) => (
                  <>
                    <Button
                      onClick={() => {
                        handleDetails(row);
                      }}
                    >
                      Details
                    </Button>
                  </>
                )
              },

              {
                name: 'Download',
                cell: (row: any) => (
                  <>
                    <Button
                      onClick={() => {
                        handleLBFile(row);
                      }}
                    >
                      Download LB
                    </Button>
                  </>
                )
              }
            ]}
            data={batches}
            responsive={true}
            highlightOnHover={true}
            progressPending={loading}
            pagination
            paginationPerPage={30}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Card.Text>
      </Card>
    </>
  );
};

export default Batches;
