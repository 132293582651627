export const GATEWAY_MERCHANTINFO = `
{
  merchantInfo {
    name
    iban
  }
}`;

export const TRANSACTIONS_QUERY = `
  {
    merchantTx {
      id
      sourced
      source
      clientRef
      description
      amount
      currencyId
    }
  }
`;

export const ADMIN_TRANSACTIONSALL_QUERY = `
  query MerchantTxAllData($page: Int, $perPage: Int) {
    merchantTxAll(page: $page, perPage: $perPage) {
      id
      cashTransferRequestId
      glBatchId
      tradeId
      dividendId
      fee
      description
      amount
      settlementDate
      tradeDate
      currencyId
      accountId
      settlementId
      entityId
      ruleFeeId
      futureSet
      reservationStatus
      futuresMatched
      refKey1
      refKey2
      refValue1
      refValue2
      txId
      address
      clientRef
      extWalletKey
      source
      sourceRef
      sourceData
      sourced
    }
  }
`;

export const ADMIN_MERCHANTTRANSACTIONS_QUERY = `
  query MerchantTxData($merchantId: String!) {
    merchantTx(merchantId: $merchantId) {
      id
      cashTransferRequestId
      glBatchId
      tradeId
      dividendId
      fee
      description
      amount
      settlementDate
      tradeDate
      currencyId
      accountId
      settlementId
      entityId
      ruleFeeId
      futureSet
      reservationStatus
      futuresMatched
      refKey1
      refKey2
      refValue1
      refValue2
      txId
      address
      clientRef
      extWalletKey
      source
      sourceRef
      sourceData
      sourced
    }
  }
`;

export const USERDATA_QUERY = `
   {
    merchantEmail
    merchantCounterpartyId
    merchantIban
  }
`;

export const MERCHANTS_QUERY = `
{ merchants { 
  pageInfo {
    hasNextPage 
    hasPreviousPage
    startCursor
    endCursor
  } 
  edges { 
    node {  
    id
    name
    email
    iban
    counterpartyId
    accountId
    approved
    fxFee
    fixedFee
    withdrawFee
    publicKeys
    webhook
    }
  }
}
}
`;

// TODO: BE missing some fields: company name, buy rate, fx markup...
export const MERCHANT_INFO_QUERY = `
   {
    merchantInfo {
      id
      email
      iban
      name
      counterpartyId
      approved
      fee
      fxFee
      fixedFee
      withdrawFee
      depositFee
      payoutFee
    }
   }
`;

export const ACCOUNT_BALANCE_QUERY = `
   {
    accountBalance {
      account
      accountType
      entityId
      entity
      clientEmail
      balance {
        amount
        entityId
        accountId
        currencyName
      }
    }
  }
`;

export const WITHDRAW_QUERY = `
  query Withdraw($amount: String, $ccyId: String) {
    withdraw(amount: $amount, ccyId: $ccyId)
  }
`;

export const ADMIN_USERDATA_QUERY = `
  query MerchantData($id: String!) {
    merchantEmail(merchantId: $id)
    merchantCounterpartyId(merchantId: $id)
    merchantIban(merchantId: $id)
    merchantFee(merchantId: $id)
    merchantFxFee(merchantId: $id)
  }
`;

export const ADMIN_PENDING_QUERY = `
  query MerchantPendingData($id: String, $personId: String) {
    merchantPending(merchantId: $id, client_pid: $personId) {
      id
      txRef
      email
      iban
      status
      buyRate
      sellRate
      sekDeposit
      eurRequest
      createdAt
      settledAt
    }
  }
`;

export const ADMIN_UPDATE_MERCHANT_BASIC_MUTATION = `
mutation MerchantUpdate(
  $email: String!
  $name: String!
  $id: String!
  $iban: String!
  $status: Boolean!
  ) {
    updateMerchant(merchantId: $id, newIban: $iban, email: $email, status: $status) {
      success
    }
  }
`;

export const ADMIN_UPDATE_MERCHANT_MUTATION = `
  mutation MerchantUpdate(
    $id: String!
    $email: String!
    $iban: String!
    $status: Boolean!
  ) {
    updateMerchant(merchantId: $id, newIban: $iban, email: $email, status: $status) {
      success
    }
  }
`;

export const ADMIN_CANCEL_MERCHANT_MUTATION = `
  mutation MerchantUpdate(
    $id: String!
    $email: String!
    $iban: String!
    $status: Boolean!
  ) {
    updateMerchant(merchantId: $id, newIban: $iban, email: $email, status: $status) {
      success
    }
  }
`;

export const ADMIN_ADD_MERCHANT_MUTATION = `
mutation AddMerchant($email: String!, $iban: String!, $name: String!) {
    addMerchant(email: $email, iban: $iban, name: $name) {
      success
    }
}
`;

export const ADMIN_REVOLUTACCOUNTS_QUERY = `
   {
    revolutAccounts {
      id
      name
      balance
      currency
      state
      public
      createdAt
      updatedAt
    }
  }
`;

export const ADMIN_CLEARING_ACCOUNTBALANCE_QUERY = `
  query getBalances {
    clearingAccountBalance: accountBalance(accountId: 1) {
      currencyId
      total
    }
  }
`;

export const ADMIN_BLOCKSETTLE_ACCOUNTBALANCE_QUERY = `
  query getBalances {
    blockSettleAccountBalance: accountBalance(accountId: 4) {
      currencyId
      total
    }
  }
`;

export const GW_RFQ = `query rfq($txRef: String!) { 
  rfq (
    txRef: $txRef,
  )
  {
    edges { 
      node {  
        txRef
        sekDeposit
        eurRequest
        clientPid
      }
    }
  }
}
`;
export const RFQ_QUERY = `query rfq($txRef: String, $pid: String, $first: Int, $last: Int, $after: String, $before: String) { 
  rfq (
    txRef: $txRef,
    clientPid: $pid,
    orderBy: ["created_at DESC"],
    first: $first,
    last: $last,
    after: $after,
    before: $before
  )
  {
    totalCount
    pageInfo {
      hasNextPage 
      hasPreviousPage
      startCursor
      endCursor
    } 
    edges { 
      node {  
        id
        txRef
        email
        iban
        status
        buyRate
        sellRate
        sekDeposit
        eurRequest
        createdAt
        settledAt
        clientPid
        kycData
        operationId
        clientIban
      }
    }
  }
}
`;

export const DEPOSIT_DETAILS = `
query ($currencyName: String) {
  depositDetails (currencyName: $currencyName)
}
`;

export const SEARCH_COUNTERPARTY = `
  query ($iban: String!) {
    searchCounterparty (iban: $iban) {
      name
      recipientType
      iban
      bic
      recipientBankCountry
      currency
      amount
      paymentReference
      recipientCountry
      addressLine1
      addressLine2
      city
      postalCode
      error
    }
  }
`;

export const LIST_COUNTERPARTY = `
{
  listCounterparty {
    name
    recipientType
    iban
    bic
    recipientBankCountry
    currency
    amount
    paymentReference
    recipientCountry
    addressLine1
    addressLine2
    city
    postalCode
  }
}
`;

export const API_KEYS = `{
  apiKeys {
    key
    status
  }
}
`;

export const CLIENT_SUMMARY = `query clientSummary($personId: String!) {
  clientSummary(personId: $personId) 
}
`;

export const CLIENT_ACCOUNT_BALANCE = `query clientAccountBalances($personId: String!) {
  clientAccountBalances(personId: $personId) {
    edges {
      node {
        actionDate
        personId
        bank
        accountsJson
      }
    }
  }
}
`;

export const REDASH_API = (resultName: string) => `query ${resultName}($queryId: String, $parameters: JSONString) 
{
  ${resultName} : redash(queryId: $queryId, parameters: $parameters)
}`;

export const GATEWAY_BANKINGHEALTH = `{
  bankingHealth
}`;

export const ADMIN_BLOCKSETTLE_FEE_ACCOUNT_QUERY = `
  query getBalances {
    feeAccountBalance: accountBalance(accountId: 5) {
      currencyId
      total
    }
  }
`;

export const ADMIN_BLOCKSETTLE_UNMATCHED_DEPOSITS_QUERY = `
  query getBalances {
    unmatchendDepositBalance: accountBalance(accountId: 3) {
      currencyId
      total
    }
  }
`;

export const CUSTOMERS_BY_MERCHANT_QUERY = `
query customers($pid: String, $first: Int, $last: Int, $after: String, $before: String) {
  customers(personId: $pid, first: $first, last: $last, after: $after, before: $before) {
    totalCount
    pageInfo {
      hasNextPage 
      hasPreviousPage
      startCursor
      endCursor
    } 
    edges { node { id personId kyc } }
  } 
}`;

export const ADMIN_CUSTOMERS_QUERY = `
query customers($pid: String, $merchantId: String, $first: Int, $last: Int, $after: String, $before: String) {
  customers(
    merchantId: $merchantId,
    personId: $pid,
    orderBy: ["person_id DESC"],
    first: $first,
    last: $last,
    after: $after,
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage 
      hasPreviousPage
      startCursor
      endCursor
    } 
    edges {
      node {
        personId
        kyc 
        merchant {
          edges {
            node {
              id
              name 
            }
          }
        } 
      } 
    }
  } 
}`;

export const CUSTOMERS_BY_PID = `
query customerByPersonId($personId: String!) {
  customerByPersonId(personId: $personId) {
    personId
    email
    kyc
  } 
}`;

export const PAYOUTS_QUERY = `
query payouts($first: Int, $last: Int, $after: String, $before: String) {
  payouts(
    first: $first,
    last: $last,
    after: $after,
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage 
      hasPreviousPage
      startCursor
      endCursor
    } 
    edges {
      node {
      merchant {
        name
        iban
      }
      customer {
        personId
        kyc
      }
      id
      clientIban
      bic
      recipientBankCountry
      currency
      paymentReference
      recipientCountry
      addressLine1
      addressLine2
      city
      postalCode
      amount
      createdAt
      status
      } 
    }
  } 
}`;

export const BATCH_QUERY = `
query batches($searchFilter: String, $first: Int, $last: Int, $after: String, $before: String) {
  batches(
    searchFilter: $searchFilter,
    first: $first,
    last: $last,
    after: $after,
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage 
      hasPreviousPage
      startCursor
      endCursor
    } 
    edges {
      node {
        createdAt
        batchId
        payouts {
          edges {
            node {
              merchant {
                name
                iban
              }
              customer {
                personId
                kyc
              }
              clientIban
              bic
              recipientBankCountry
              currency
              paymentReference
              recipientCountry
              addressLine1
              addressLine2
              city
              postalCode
              amount
              status
              createdAt
            }
          }
        }
      } 
    }
  } 
}`;
